
const state = {
  sidebar: {},
}

const mutations = {
  
}

const actions = {
  
}

export default {
  state,
  mutations,
  actions
}
