import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookies from 'js-cookie'

Vue.use(VueI18n)

const messages = {
  lang0: {
    ...pro_lang0
  },
  lang1: {
    ...pro_lang1
  },
  lang2: {
    ...pro_lang2
  },
  lang3: {
    ...pro_lang3
  },
  lang4: {
    ...pro_lang4
  }
};
export function getLanguage() {
  const chooseLanguage = localStorage.getItem("language")
    ? "lang" + localStorage.getItem("language")
    : "lang0";
  console.log(localStorage.getItem("language"), "111111111");
  
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return "lang0";
}
console.log(1111111, getLanguage())
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
